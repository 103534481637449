import { debug } from "console";

const axios = require('axios');
const API_ENDPOINT = (process.env.REACT_APP_API_ENDPOINT || window.location.origin) + '/api/';
const ENDPOINT = (process.env.REACT_APP_API_ENDPOINT || window.location.origin);

const client = {
    get: async (url: string, params: any) => {
        try {

            let token = client.getAuth();
            let response = await axios.get(
                `${API_ENDPOINT}${url}`,
                { params: params, headers: token === undefined ? {} : { 'Authorization': `Bearer ${token}` } },
            )

            return client.handleResponse(response);

        } catch (error: any) {
            return client.handleError(error);
        }
    },
    getFile: async (url: string, params: any) => {
        try {

            let token = client.getAuth();
            let response = await axios.get(
                `${API_ENDPOINT}${url}`,
                { params: params, responseType: 'blob', headers: token === undefined ? {} : { 'Authorization': `Bearer ${token}` } },
            )

            return response;

        } catch (error: any) {
            return client.handleError(error);
        }
    },
    handleResponse: async (response: any) => {
        return {
            error: false,
            success: true,
            message: '',
            response: '',
            data: Array.isArray(response.data) ? [...response.data] : { ...response.data },
        }
    },
    handleError: async (error: any) => {
        if (error.response.status === 401) {
            client.logout();
            window.location.href = './../Logout';

        }
        return {
            error: true, success: false,
            data: {}
            ,
            response: { ...error.response },
            message: error.toString(),
        }
    },
    post: async (url: string, params: any) => {
        try {
            let token = client.getAuth();
            let response = await axios.post(
                `${API_ENDPOINT}${url}`,
                params,
                {
                    headers: token === undefined ? {} : { 'Authorization': `Bearer ${token}` },
                })
            return client.handleResponse(response);
        } catch (error: any) {
            return client.handleError(error);
        }
    },
    postFormData: async (url: string, params: any) => {
        try {
            let token = client.getAuth();
            let response = await axios.post(
                `${API_ENDPOINT}${url}`,
                params,
                {
                    headers: token === undefined ? { 'Content-Type': 'multipart/form-data', } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data', },
                })
            return client.handleResponse(response);
        } catch (error: any) {
            return client.handleError(error);
        }
    },
    upload: async (type: string, blob: any) => {
        const form: any = new FormData();
        form.append('file', blob);
        form.append('type', type); // banners|products|etc
        try {
            let token = client.getAuth();
            let response = await axios.put(
                `${API_ENDPOINT}/upload`,
                form,
                {
                    headers: {
                        'x-token': `Bearer ${token}`,
                        'content-type': 'multipart/form-data; '
                    }
                })
            return {
                error: false,
                ...response.data,
            }
        } catch (error: any) {
            return client.handleError(error);
        }
    },
    login: async (params: any) => {
        try {
            let response = await axios.post(
                `${ENDPOINT}/connect/token`,
                params,
                {
                    headers: {},
                })
            return client.handleResponse(response);
        } catch (error: any) {
            return client.handleError(error);
        }
    },
    userInfo: async (access_token: any) => {
        try {
            let response = await axios.get(
                `${ENDPOINT}/connect/userinfo`,
                { headers: { 'Authorization': `Bearer ${access_token}` } },
            )
            return client.handleResponse(response);
        } catch (error: any) {
            return client.handleError(error);
        }
    },
    dashboard: async () => {
        try {
            let access_token = client.getAuth();
            let response = await axios.get(
                `${API_ENDPOINT}v1/dashboard`,
                { headers: { 'Authorization': `Bearer ${access_token}` } },
            )
            return client.handleResponse(response);
        } catch (error: any) {
            return client.handleError(error);
        }
    },
    logout: async () => {
        try {

            let response = await axios.post(
                `${ENDPOINT}/connect/revocation`,
                new URLSearchParams({
                    'token': client.getAuth(),
                    'token_type_hint': 'access_token'
                }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Basic ' + btoa('CNEClient:')
                    },
                })
            return client.handleResponse(response);
        } catch (error: any) {
            return client.handleError(error);
        }
    },
    getAuth: () => {
        let auth: any = window.localStorage.getItem('auth');
        if (auth) {
            auth = JSON.parse(auth);
            return auth.token;
        }
    },
    getUserDetailsByEmail: async (email) => {
        try {
            let token = client.getAuth();
            let response = await axios.get(
                `${API_ENDPOINT}userDetails`,
                { params: { email }, headers: token === undefined ? {} : { 'Authorization': `Bearer ${token}` } }
            );
            return client.handleResponse(response);
        } catch (error) {
            return client.handleError(error);
        }
    },
}

export default client;