

export const PRICING_TYPE = {
    PAY_AS_YOU_GO: 'Pay As You Go',
    MONTHLY_SUBSCRIBTION: 'Monthly Subscription'
}

export const bookingStatuses = [
    { value: 'booked', label: 'Booked' },
    { value: 'inProcess', label: 'In Process' },
    { value: 'inTransit', label: 'In Transit' },
    { value: 'completed', label: 'Completed' }
];

export const customsClearanceStatuses = [
    { value: 'documentsReceived', label: 'Documents received' },
    { value: 'entryAccepted', label: 'Entry accepted' },
    { value: 'customInspection', label: 'Custom Inspection' },
    { value: 'customsCleared', label: 'Customs cleared' }
];

export const VENDORS = {
    veriphy: 'veriphy',
    trustid: 'trustid',
    yoti: 'yoti',
    creditSage: 'CreditSafe'
}

export const TRUSTID_SERVICES = [
    { Type: "TrustID", ServiceCode: "DrivingLicense", Name: "Driving License", Title: "Driving License Check", Short: "" },
    { Type: "TrustID", ServiceCode: "IDCard", Name: "ID Card", Title: "ID Card Check", Short: "" },
    { Type: "TrustID", ServiceCode: "Passport", Name: "Passport", Title: "Passport Check", Short: "" }
];

export const validatePassword = (_, value) => {
    if (!value) {
        return Promise.reject(new Error('Password is required'));
    }

    const criteria = [
        { regex: /.{8,}/, message: 'Minimum 8 characters' },
        { regex: /[A-Z]/, message: 'At least one uppercase letter (A-Z)' },
        { regex: /[a-z]/, message: 'At least one lowercase letter (a-z)' },
        { regex: /[0-9]/, message: 'At least one digit (0-9)' },
        { regex: /[!@#$%^&*]/, message: 'At least one special character (e.g., !@#$%^&*)' },
    ];

    const errors = criteria
        .filter(criterion => !criterion.regex.test(value))
        .map(criterion => criterion.message);

    if (errors.length > 0) {
        return Promise.reject(new Error(errors.join(', ')));
    }

    return Promise.resolve();
};

export const passwordRules = [{
    required: true,
    message: (
        <>
            Minimum 8 characters<br />
            At least one uppercase letter (A-Z)<br />
            At least one lowercase letter (a-z)<br />
            At least one digit (0-9)<br />
            At least one special character (e.g., !@#$%^&*)<br />
        </>
    )
}, { validator: validatePassword }];

export const VERIPHY_APIS = [
    {
        Type: "IDAML", ServiceCode: "IDAMLNOCRED", Name: "AML Veriphy", Title: "Anti-Money Laundering Check", Short: "Comprehensive compliant AML checks for UK resident…, driving licences and more. Includes PEP checks.",
        Desc: [
            'JMLSG & 5th Directive compliant UK address and ID verification, with integral searches of full & edited electoral registers (current and historic), recent credit file activity data, births index, telephone directory, mortality, departure, sanctions (HMT, EU, and OFAC) and PEP (UK domestic and global), and optional checking of passport, GB driving licence and National Insurance numbers. Suitable for AML and other ID checks on any individual of any nationality who is currently resident in the UK.',
            'Gender, first name, surname, date of birth and current address are required. If you are entering a non-UK passport number, please include any < symbols.'
        ]
    }
    , {
        Type: "IDAML", ServiceCode: "CREDACTIVE", Name: "Credit Veriphy", Title: "Credit Screen", Short: "Screens UK Individuals for CCJs past and present, …putes/correction orders and Scottish equivalents.",
        Desc: ['This check screens UK residents for CCJs, bankruptcies, IVAs and Scottish equivalents. The score returned reflects the residency matches, and will be negative if adverse public credit data is present.']
    }
    , {
        Type: "IDAML", ServiceCode: "IDAML", Name: "AML/Credit Veriphy", Title: "Anti-Money Laundering & Credit Screen", Short: "Combined AML check and credit screen for UK residents.",
        Desc: [
            'JMLSG & 5th Directive compliant UK address and ID verification, with integral searches of full & edited electoral registers (current and historic), recent credit file activity data, births index, telephone directory, mortality, departure, sanctions (HMT, EU, and OFAC) and PEP (UK domestic and global), and optional checking of passport, GB driving licence and National Insurance numbers, combined with screening for CCJs, bankruptcies, IVAs and Scottish equivalents.'
        ]
    }
    , {
        Type: "IDAML", ServiceCode: "INTID", Name: "International ID Veriphy", Title: "International ID Check", Short: "Checks anyone living overseas (including UK nation…ability to check passports, EU ID cards and more.",
        Desc: [
            'This check may be run on any individual when address is not relevant or if the person does not reside in the UK. It checks the PEP and Sanctions lists (HMT, EU, CIA and OFAC), and allows document validation. For AML purposes the overseas address should be verified by documentary or other means.',
            'Gender, first name, surname and date of birth are required. If you are entering a non-UK passport number, please include any < symbols.',
            'You may use this check to validate any machine-readable passport (including Convention Travel Documents), EU-compliant ID cards, and biometric UK Residence Permits.'
        ]
    }
    , {
        Type: "IDAML", ServiceCode: "HR", Name: "HR Veriphy", Title: "HR Screen", Short: "The Veriphy HR check is a combined AML check and c…gether with bank account and directorship checks.",
        Desc: [
            'JMLSG & 5th Directive compliant UK address and ID verification, with integral searches of full & edited electoral registers (current and historic), recent credit file activity data, births index, telephone directory, mortality, departure, sanctions (HMT, EU, and OFAC) and PEP (UK domestic and global), and optional checking of passport, GB driving licence and National Insurance numbers.',
            'Screening for CCJs, bankruptcies, IVAs and Scottish equivalents.',
            'Validation of UK bank account details.',
            'Check for current UK directorships.'
        ]
    }
    , { Type: "IDAML", ServiceCode: "DIRSEARCH", Name: "Director Veriphy", Title: "Director Search", Short: "Looks for current UK directorships held by an individual.", Desc: [] }
    , { Type: "IDAML", ServiceCode: "ROUTE2", Name: "ID Veriphy", Title: "Identity Check", Short: "Identity and address validation, suitable for DBS Route 2", Desc: ['An external ID check in accordance with the stipulations of the DBS.'] }
    , {
        Type: "IDAML", ServiceCode: "360", Name: "ID Veriphy", Title: "Veriphy 360", Short: "Veriphy 360 Check - all our checks on individuals … in one clear, powerful and comprehensive report.",
        Desc: [
            'All the elements of the Combined AML Check and Credit Screen, with additional Directorship Check and UK bank account validation, together with screening of UK and EU visas, UK entry clearance documents and EU ID cards.'
        ]
    }
    , {
        Type: "IDAML", ServiceCode: "VISA", Name: "Visa Veriphy", Title: "Travel Visa Check", Short: "Screens UK & European Visas, and UK Entry Clearance documents",
        Desc: [
            'Our Travel Visa Check may be used to screen UK and EU Travel Visas and UK Entry Clearance documents in order to assess an individual\'s right to reside in the UK.'
        ]
    }
    , {
        Type: "IDAML", ServiceCode: "PEPSANCTION", Name: "AML Veriphy", Title: "PEP & Sanctions", Short: "", Desc: [
            'A check against the sanctions lists (HMT, EU, OFAC) and the WorldCompliance PEP register (UK domestic and global).'
        ]
    }
    , { Type: "IDAML", ServiceCode: "ER", Name: "AML Veriphy", Title: "Electoral Roll", Short: "ER Check", Desc: [] }

    /*
    ,{Type:"Company",ServiceCode: "COMPUK", Name: "Company Veriphy", Title: "UK Company Check", Short: "Comprehensive reports on UK companies & LLPs: iden…reholdings, group structure, financials and more.",Desc:[]}
    ,{Type:"Company",ServiceCode: "COMPINT", Name: "Company Veriphy", Title: "International Company Check", Short: "Comprehensive reports on overseas companies.",Desc:[]}
    
    ,{Type:"Lawyer",ServiceCode: "LAWYERCHECK", Name: "Lawyer Veriphy", Title: "Lawyer Check", Short: "Veriphy Lawyer Check will identify if there is a r…e associated with a vendor conveyancer's account.",Desc:[]}
    */
]

export const YOTI_SERVICES = [
    { Type: "Yoti", ServiceCode: "Identity verification", Name: "Identity verification", Title: "Identity verification check", Short: "" }
];


export const CreditSafe_SERVICES = [
    { Type: "Yoti", ServiceCode: "Person Search", Name: "Person Search", Title: "Search Person Record", Short: "" }
];


export const deliveryTerms = [

    { value: 'CFR', label: 'CFR - Cost and freight Named place of destination' },
    { value: 'CIF', label: 'CIF - Cost, insurance and freight Named place of destination' },
    { value: 'CIP', label: 'CIP - Carriage and insurance paid to Named place of destination' },
    { value: 'CPT', label: 'CPT - Carriage paid to Named place of destination' },
    { value: 'DAP', label: 'DAP - Delivered at place Named place of destination' },
    { value: 'DAT', label: 'DAT - Delivered at terminal Named terminal at port or place of destination' },
    { value: 'DDP', label: 'DDP - Delivered duty paid Named place of destination' },
    { value: 'DPU', label: 'DPU - DPU' },
    { value: 'EXW', label: 'EXW - Ex works Named place' },
    { value: 'FAS', label: 'FAS - Free along ship' },
    { value: 'FCA', label: 'FCA - Free carrier Named place' },
    { value: 'FOB', label: 'FOB - Free on board Named port of shipment' },
    { value: 'XXX', label: 'XXX - Delivery terms other than those previously listed' }
];

export const countries = [
    { value: 'AF', label: 'Afghanistan', flagClass: 'fi fi-af' },
    { value: 'AX', label: 'Åland Islands', flagClass: 'fi fi-ax' },
    { value: 'AL', label: 'Albania', flagClass: 'fi fi-al' },
    { value: 'DZ', label: 'Algeria', flagClass: 'fi fi-dz' },
    { value: 'AS', label: 'American Samoa', flagClass: 'fi fi-as' },
    { value: 'AD', label: 'Andorra', flagClass: 'fi fi-ad' },
    { value: 'AO', label: 'Angola', flagClass: 'fi fi-ao' },
    { value: 'AI', label: 'Anguilla', flagClass: 'fi fi-ai' },
    { value: 'AQ', label: 'Antarctica', flagClass: 'fi fi-aq' },
    { value: 'AG', label: 'Antigua and Barbuda', flagClass: 'fi fi-ag' },
    { value: 'AR', label: 'Argentina', flagClass: 'fi fi-ar' },
    { value: 'AM', label: 'Armenia', flagClass: 'fi fi-am' },
    { value: 'AW', label: 'Aruba', flagClass: 'fi fi-aw' },
    { value: 'AU', label: 'Australia', flagClass: 'fi fi-au' },
    { value: 'AT', label: 'Austria', flagClass: 'fi fi-at' },
    { value: 'AZ', label: 'Azerbaijan', flagClass: 'fi fi-az' },
    { value: 'BS', label: 'Bahamas', flagClass: 'fi fi-bs' },
    { value: 'BH', label: 'Bahrain', flagClass: 'fi fi-bh' },
    { value: 'BD', label: 'Bangladesh', flagClass: 'fi fi-bd' },
    { value: 'BB', label: 'Barbados', flagClass: 'fi fi-bb' },
    { value: 'BY', label: 'Belarus', flagClass: 'fi fi-by' },
    { value: 'BE', label: 'Belgium', flagClass: 'fi fi-be' },
    { value: 'BZ', label: 'Belize', flagClass: 'fi fi-bz' },
    { value: 'BJ', label: 'Benin', flagClass: 'fi fi-bj' },
    { value: 'BM', label: 'Bermuda', flagClass: 'fi fi-bm' },
    { value: 'BT', label: 'Bhutan', flagClass: 'fi fi-bt' },
    { value: 'BO', label: 'Bolivia', flagClass: 'fi fi-bo' },
    { value: 'BA', label: 'Bosnia and Herzegovina', flagClass: 'fi fi-ba' },
    { value: 'BW', label: 'Botswana', flagClass: 'fi fi-bw' },
    { value: 'BV', label: 'Bouvet Island', flagClass: 'fi fi-bv' },
    { value: 'BR', label: 'Brazil', flagClass: 'fi fi-br' },
    { value: 'IO', label: 'British Indian Ocean Territory', flagClass: 'fi fi-io' },
    { value: 'BN', label: 'Brunei Darussalam', flagClass: 'fi fi-bn' },
    { value: 'BG', label: 'Bulgaria', flagClass: 'fi fi-bg' },
    { value: 'BF', label: 'Burkina Faso', flagClass: 'fi fi-bf' },
    { value: 'BI', label: 'Burundi', flagClass: 'fi fi-bi' },
    { value: 'CV', label: 'Cabo Verde', flagClass: 'fi fi-cv' },
    { value: 'KH', label: 'Cambodia', flagClass: 'fi fi-kh' },
    { value: 'CM', label: 'Cameroon', flagClass: 'fi fi-cm' },
    { value: 'CA', label: 'Canada', flagClass: 'fi fi-ca' },
    { value: 'KY', label: 'Cayman Islands', flagClass: 'fi fi-ky' },
    { value: 'CF', label: 'Central African Republic', flagClass: 'fi fi-cf' },
    { value: 'TD', label: 'Chad', flagClass: 'fi fi-td' },
    { value: 'CL', label: 'Chile', flagClass: 'fi fi-cl' },
    { value: 'CN', label: 'China', flagClass: 'fi fi-cn' },
    { value: 'CX', label: 'Christmas Island', flagClass: 'fi fi-cx' },
    { value: 'CC', label: 'Cocos (Keeling) Islands', flagClass: 'fi fi-cc' },
    { value: 'CO', label: 'Colombia', flagClass: 'fi fi-co' },
    { value: 'KM', label: 'Comoros', flagClass: 'fi fi-km' },
    { value: 'CD', label: 'Congo (the Democratic Republic of the)', flagClass: 'fi fi-cd' },
    { value: 'CG', label: 'Congo', flagClass: 'fi fi-cg' },
    { value: 'CK', label: 'Cook Islands', flagClass: 'fi fi-ck' },
    { value: 'CR', label: 'Costa Rica', flagClass: 'fi fi-cr' },
    { value: 'HR', label: 'Croatia', flagClass: 'fi fi-hr' },
    { value: 'CU', label: 'Cuba', flagClass: 'fi fi-cu' },
    { value: 'CW', label: 'Curaçao', flagClass: 'fi fi-cw' },
    { value: 'CY', label: 'Cyprus', flagClass: 'fi fi-cy' },
    { value: 'CZ', label: 'Czechia', flagClass: 'fi fi-cz' },
    { value: 'CI', label: "Côte d'Ivoire", flagClass: 'fi fi-ci' },
    { value: 'DK', label: 'Denmark', flagClass: 'fi fi-dk' },
    { value: 'DJ', label: 'Djibouti', flagClass: 'fi fi-dj' },
    { value: 'DM', label: 'Dominica', flagClass: 'fi fi-dm' },
    { value: 'DO', label: 'Dominican Republic', flagClass: 'fi fi-do' },
    { value: 'EC', label: 'Ecuador', flagClass: 'fi fi-ec' },
    { value: 'EG', label: 'Egypt', flagClass: 'fi fi-eg' },
    { value: 'SV', label: 'El Salvador', flagClass: 'fi fi-sv' },
    { value: 'GQ', label: 'Equatorial Guinea', flagClass: 'fi fi-gq' },
    { value: 'ER', label: 'Eritrea', flagClass: 'fi fi-er' },
    { value: 'EE', label: 'Estonia', flagClass: 'fi fi-ee' },
    { value: 'SZ', label: 'Eswatini', flagClass: 'fi fi-sz' },
    { value: 'ET', label: 'Ethiopia', flagClass: 'fi fi-et' },
    { value: 'FK', label: 'Falkland Islands (Malvinas)', flagClass: 'fi fi-fk' },
    { value: 'FO', label: 'Faroe Islands', flagClass: 'fi fi-fo' },
    { value: 'FJ', label: 'Fiji', flagClass: 'fi fi-fj' },
    { value: 'FI', label: 'Finland', flagClass: 'fi fi-fi' },
    { value: 'FR', label: 'France', flagClass: 'fi fi-fr' },
    { value: 'GF', label: 'French Guiana', flagClass: 'fi fi-gf' },
    { value: 'PF', label: 'French Polynesia', flagClass: 'fi fi-pf' },
    { value: 'TF', label: 'French Southern Territories', flagClass: 'fi fi-tf' },
    { value: 'GA', label: 'Gabon', flagClass: 'fi fi-ga' },
    { value: 'GM', label: 'Gambia', flagClass: 'fi fi-gm' },
    { value: 'GE', label: 'Georgia', flagClass: 'fi fi-ge' },
    { value: 'DE', label: 'Germany', flagClass: 'fi fi-de' },
    { value: 'GH', label: 'Ghana', flagClass: 'fi fi-gh' },
    { value: 'GI', label: 'Gibraltar', flagClass: 'fi fi-gi' },
    { value: 'GR', label: 'Greece', flagClass: 'fi fi-gr' },
    { value: 'GL', label: 'Greenland', flagClass: 'fi fi-gl' },
    { value: 'GD', label: 'Grenada', flagClass: 'fi fi-gd' },
    { value: 'GP', label: 'Guadeloupe', flagClass: 'fi fi-gp' },
    { value: 'GU', label: 'Guam', flagClass: 'fi fi-gu' },
    { value: 'GT', label: 'Guatemala', flagClass: 'fi fi-gt' },
    { value: 'GG', label: 'Guernsey', flagClass: 'fi fi-gg' },
    { value: 'GN', label: 'Guinea', flagClass: 'fi fi-gn' },
    { value: 'GW', label: 'Guinea-Bissau', flagClass: 'fi fi-gw' },
    { value: 'GY', label: 'Guyana', flagClass: 'fi fi-gy' },
    { value: 'HT', label: 'Haiti', flagClass: 'fi fi-ht' },
    { value: 'HM', label: 'Heard Island and McDonald Islands', flagClass: 'fi fi-hm' },
    { value: 'VA', label: 'Holy See', flagClass: 'fi fi-va' },
    { value: 'HN', label: 'Honduras', flagClass: 'fi fi-hn' },
    { value: 'HK', label: 'Hong Kong', flagClass: 'fi fi-hk' },
    { value: 'HU', label: 'Hungary', flagClass: 'fi fi-hu' },
    { value: 'IS', label: 'Iceland', flagClass: 'fi fi-is' },
    { value: 'IN', label: 'India', flagClass: 'fi fi-in' },
    { value: 'ID', label: 'Indonesia', flagClass: 'fi fi-id' },
    { value: 'IR', label: 'Iran', flagClass: 'fi fi-ir' },
    { value: 'IQ', label: 'Iraq', flagClass: 'fi fi-iq' },
    { value: 'IE', label: 'Ireland', flagClass: 'fi fi-ie' },
    { value: 'IM', label: 'Isle of Man', flagClass: 'fi fi-im' },
    { value: 'IL', label: 'Israel', flagClass: 'fi fi-il' },
    { value: 'IT', label: 'Italy', flagClass: 'fi fi-it' },
    { value: 'JM', label: 'Jamaica', flagClass: 'fi fi-jm' },
    { value: 'JP', label: 'Japan', flagClass: 'fi fi-jp' },
    { value: 'JE', label: 'Jersey', flagClass: 'fi fi-je' },
    { value: 'JO', label: 'Jordan', flagClass: 'fi fi-jo' },
    { value: 'KZ', label: 'Kazakhstan', flagClass: 'fi fi-kz' },
    { value: 'KE', label: 'Kenya', flagClass: 'fi fi-ke' },
    { value: 'KI', label: 'Kiribati', flagClass: 'fi fi-ki' },
    { value: 'KP', label: 'Korea (the Democratic People\'s Republic of)', flagClass: 'fi fi-kp' },
    { value: 'KR', label: 'Korea (the Republic of)', flagClass: 'fi fi-kr' },
    { value: 'KW', label: 'Kuwait', flagClass: 'fi fi-kw' },
    { value: 'KG', label: 'Kyrgyzstan', flagClass: 'fi fi-kg' },
    { value: 'LA', label: 'Lao People\'s Democratic Republic', flagClass: 'fi fi-la' },
    { value: 'LV', label: 'Latvia', flagClass: 'fi fi-lv' },
    { value: 'LB', label: 'Lebanon', flagClass: 'fi fi-lb' },
    { value: 'LS', label: 'Lesotho', flagClass: 'fi fi-ls' },
    { value: 'LR', label: 'Liberia', flagClass: 'fi fi-lr' },
    { value: 'LY', label: 'Libya', flagClass: 'fi fi-ly' },
    { value: 'LI', label: 'Liechtenstein', flagClass: 'fi fi-li' },
    { value: 'LT', label: 'Lithuania', flagClass: 'fi fi-lt' },
    { value: 'LU', label: 'Luxembourg', flagClass: 'fi fi-lu' },
    { value: 'MO', label: 'Macao', flagClass: 'fi fi-mo' },
    { value: 'MG', label: 'Madagascar', flagClass: 'fi fi-mg' },
    { value: 'MW', label: 'Malawi', flagClass: 'fi fi-mw' },
    { value: 'MY', label: 'Malaysia', flagClass: 'fi fi-my' },
    { value: 'MV', label: 'Maldives', flagClass: 'fi fi-mv' },
    { value: 'ML', label: 'Mali', flagClass: 'fi fi-ml' },
    { value: 'MT', label: 'Malta', flagClass: 'fi fi-mt' },
    { value: 'MH', label: 'Marshall Islands', flagClass: 'fi fi-mh' },
    { value: 'MQ', label: 'Martinique', flagClass: 'fi fi-mq' },
    { value: 'MR', label: 'Mauritania', flagClass: 'fi fi-mr' },
    { value: 'MU', label: 'Mauritius', flagClass: 'fi fi-mu' },
    { value: 'YT', label: 'Mayotte', flagClass: 'fi fi-yt' },
    { value: 'MX', label: 'Mexico', flagClass: 'fi fi-mx' },
    { value: 'FM', label: 'Micronesia (Federated States of)', flagClass: 'fi fi-fm' },
    { value: 'MD', label: 'Moldova (the Republic of)', flagClass: 'fi fi-md' },
    { value: 'MC', label: 'Monaco', flagClass: 'fi fi-mc' },
    { value: 'MN', label: 'Mongolia', flagClass: 'fi fi-mn' },
    { value: 'ME', label: 'Montenegro', flagClass: 'fi fi-me' },
    { value: 'MS', label: 'Montserrat', flagClass: 'fi fi-ms' },
    { value: 'MA', label: 'Morocco', flagClass: 'fi fi-ma' },
    { value: 'MZ', label: 'Mozambique', flagClass: 'fi fi-mz' },
    { value: 'MM', label: 'Myanmar', flagClass: 'fi fi-mm' },
    { value: 'NA', label: 'Namibia', flagClass: 'fi fi-na' },
    { value: 'NR', label: 'Nauru', flagClass: 'fi fi-nr' },
    { value: 'NP', label: 'Nepal', flagClass: 'fi fi-np' },
    { value: 'NL', label: 'Netherlands', flagClass: 'fi fi-nl' },
    { value: 'NC', label: 'New Caledonia', flagClass: 'fi fi-nc' },
    { value: 'NZ', label: 'New Zealand', flagClass: 'fi fi-nz' },
    { value: 'NI', label: 'Nicaragua', flagClass: 'fi fi-ni' },
    { value: 'NE', label: 'Niger', flagClass: 'fi fi-ne' },
    { value: 'NG', label: 'Nigeria', flagClass: 'fi fi-ng' },
    { value: 'NU', label: 'Niue', flagClass: 'fi fi-nu' },
    { value: 'NF', label: 'Norfolk Island', flagClass: 'fi fi-nf' },
    { value: 'MP', label: 'Northern Mariana Islands', flagClass: 'fi fi-mp' },
    { value: 'NO', label: 'Norway', flagClass: 'fi fi-no' },
    { value: 'OM', label: 'Oman', flagClass: 'fi fi-om' },
    { value: 'PK', label: 'Pakistan', flagClass: 'fi fi-pk' },
    { value: 'PW', label: 'Palau', flagClass: 'fi fi-pw' },
    { value: 'PS', label: 'Palestine, State of', flagClass: 'fi fi-ps' },
    { value: 'PA', label: 'Panama', flagClass: 'fi fi-pa' },
    { value: 'PG', label: 'Papua New Guinea', flagClass: 'fi fi-pg' },
    { value: 'PY', label: 'Paraguay', flagClass: 'fi fi-py' },
    { value: 'PE', label: 'Peru', flagClass: 'fi fi-pe' },
    { value: 'PH', label: 'Philippines', flagClass: 'fi fi-ph' },
    { value: 'PN', label: 'Pitcairn', flagClass: 'fi fi-pn' },
    { value: 'PL', label: 'Poland', flagClass: 'fi fi-pl' },
    { value: 'PT', label: 'Portugal', flagClass: 'fi fi-pt' },
    { value: 'PR', label: 'Puerto Rico', flagClass: 'fi fi-pr' },
    { value: 'QA', label: 'Qatar', flagClass: 'fi fi-qa' },
    { value: 'MK', label: 'Republic of North Macedonia', flagClass: 'fi fi-mk' },
    { value: 'RO', label: 'Romania', flagClass: 'fi fi-ro' },
    { value: 'RU', label: 'Russian Federation', flagClass: 'fi fi-ru' },
    { value: 'RW', label: 'Rwanda', flagClass: 'fi fi-rw' },
    { value: 'RE', label: 'Réunion', flagClass: 'fi fi-re' },
    { value: 'BL', label: 'Saint Barthélemy', flagClass: 'fi fi-bl' },
    { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha', flagClass: 'fi fi-sh' },
    { value: 'KN', label: 'Saint Kitts and Nevis', flagClass: 'fi fi-kn' },
    { value: 'LC', label: 'Saint Lucia', flagClass: 'fi fi-lc' },
    { value: 'MF', label: 'Saint Martin (French part)', flagClass: 'fi fi-mf' },
    { value: 'PM', label: 'Saint Pierre and Miquelon', flagClass: 'fi fi-pm' },
    { value: 'VC', label: 'Saint Vincent and the Grenadines', flagClass: 'fi fi-vc' },
    { value: 'WS', label: 'Samoa', flagClass: 'fi fi-ws' },
    { value: 'SM', label: 'San Marino', flagClass: 'fi fi-sm' },
    { value: 'ST', label: 'Sao Tome and Principe', flagClass: 'fi fi-st' },
    { value: 'SA', label: 'Saudi Arabia', flagClass: 'fi fi-sa' },
    { value: 'SN', label: 'Senegal', flagClass: 'fi fi-sn' },
    { value: 'RS', label: 'Serbia', flagClass: 'fi fi-rs' },
    { value: 'SC', label: 'Seychelles', flagClass: 'fi fi-sc' },
    { value: 'SL', label: 'Sierra Leone', flagClass: 'fi fi-sl' },
    { value: 'SG', label: 'Singapore', flagClass: 'fi fi-sg' },
    { value: 'SX', label: 'Sint Maarten (Dutch part)', flagClass: 'fi fi-sx' },
    { value: 'SK', label: 'Slovakia', flagClass: 'fi fi-sk' },
    { value: 'SI', label: 'Slovenia', flagClass: 'fi fi-si' },
    { value: 'SB', label: 'Solomon Islands', flagClass: 'fi fi-sb' },
    { value: 'SO', label: 'Somalia', flagClass: 'fi fi-so' },
    { value: 'ZA', label: 'South Africa', flagClass: 'fi fi-za' },
    { value: 'GS', label: 'South Georgia and the South Sandwich Islands', flagClass: 'fi fi-gs' },
    { value: 'SS', label: 'South Sudan', flagClass: 'fi fi-ss' },
    { value: 'ES', label: 'Spain', flagClass: 'fi fi-es' },
    { value: 'LK', label: 'Sri Lanka', flagClass: 'fi fi-lk' },
    { value: 'SD', label: 'Sudan', flagClass: 'fi fi-sd' },
    { value: 'SR', label: 'Suriname', flagClass: 'fi fi-sr' },
    { value: 'SJ', label: 'Svalbard and Jan Mayen', flagClass: 'fi fi-sj' },
    { value: 'SE', label: 'Sweden', flagClass: 'fi fi-se' },
    { value: 'CH', label: 'Switzerland', flagClass: 'fi fi-ch' },
    { value: 'SY', label: 'Syrian Arab Republic', flagClass: 'fi fi-sy' },
    { value: 'TW', label: 'Taiwan', flagClass: 'fi fi-tw' },
    { value: 'TJ', label: 'Tajikistan', flagClass: 'fi fi-tj' },
    { value: 'TZ', label: 'Tanzania, United Republic of', flagClass: 'fi fi-tz' },
    { value: 'TH', label: 'Thailand', flagClass: 'fi fi-th' },
    { value: 'TL', label: 'Timor-Leste', flagClass: 'fi fi-tl' },
    { value: 'TG', label: 'Togo', flagClass: 'fi fi-tg' },
    { value: 'TK', label: 'Tokelau', flagClass: 'fi fi-tk' },
    { value: 'TO', label: 'Tonga', flagClass: 'fi fi-to' },
    { value: 'TT', label: 'Trinidad and Tobago', flagClass: 'fi fi-tt' },
    { value: 'TN', label: 'Tunisia', flagClass: 'fi fi-tn' },
    { value: 'TR', label: 'Turkey', flagClass: 'fi fi-tr' },
    { value: 'TM', label: 'Turkmenistan', flagClass: 'fi fi-tm' },
    { value: 'TC', label: 'Turks and Caicos Islands', flagClass: 'fi fi-tc' },
    { value: 'TV', label: 'Tuvalu', flagClass: 'fi fi-tv' },
    { value: 'UG', label: 'Uganda', flagClass: 'fi fi-ug' },
    { value: 'UA', label: 'Ukraine', flagClass: 'fi fi-ua' },
    { value: 'AE', label: 'United Arab Emirates', flagClass: 'fi fi-ae' },
    { value: 'GB', label: 'United Kingdom', flagClass: 'fi fi-gb' },
    { value: 'UM', label: 'United States Minor Outlying Islands', flagClass: 'fi fi-um' },
    { value: 'US', label: 'United States of America', flagClass: 'fi fi-us' },
    { value: 'UY', label: 'Uruguay', flagClass: 'fi fi-uy' },
    { value: 'UZ', label: 'Uzbekistan', flagClass: 'fi fi-uz' },
    { value: 'VU', label: 'Vanuatu', flagClass: 'fi fi-vu' },
    { value: 'VE', label: 'Venezuela', flagClass: 'fi fi-ve' },
    { value: 'VN', label: 'Viet Nam', flagClass: 'fi fi-vn' },
    { value: 'VG', label: 'Virgin Islands (British)', flagClass: 'fi fi-vg' },
    { value: 'VI', label: 'Virgin Islands (U.S.)', flagClass: 'fi fi-vi' },
    { value: 'WF', label: 'Wallis and Futuna', flagClass: 'fi fi-wf' },
    { value: 'EH', label: 'Western Sahara', flagClass: 'fi fi-eh' },
    { value: 'YE', label: 'Yemen', flagClass: 'fi fi-ye' },
    { value: 'ZM', label: 'Zambia', flagClass: 'fi fi-zm' },
    { value: 'ZW', label: 'Zimbabwe', flagClass: 'fi fi-zw' }
];
