import React, { lazy, Suspense, Fragment } from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    Navigate,
    Outlet
} from "react-router-dom";
import { useSelector } from 'react-redux';
import ChangePassword from './pages/ChangePassword';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import NewQuote from './pages/NewQuote';

// admin

// customer
const Login = lazy(() => import('./pages/Login'));
const Logout = lazy(() => import('./pages/Logout'));
const Registration = lazy(() => import('./pages/Registration'));
const Activate = lazy(() => import('./pages/Activate'));

const MyAccount = lazy(() => import('./pages/MyAccount'));
const HomePage = lazy(() => import('./pages/index'));
const EditProfile = lazy(() => import('./pages/EditProfile'));
const NewBooking = lazy(() => import('./pages/NewBooking'));
const Bookings = lazy(() => import('./pages/Bookings'));
const Quotes = lazy(() => import('./pages/Quotes'));
const Invoices = lazy(() => import('./pages/Invoices'));
const Users = lazy(() => import('./pages/admin/Users'));
const NewUserForm = lazy(() => import('./pages/NewUserForm'));

const NewQuoteForm = lazy(() => import('./pages/NewQuoteForm'));
const NewBookingForm = lazy(() => import('./pages/NewBookingForm'));
const ForgottenPassword = lazy(() => import('./pages/ForgottenPassword'));

const AuthRoute = (props: any) => {
    const isLogin = useSelector((state: any) => state.isLogin);
    const user = useSelector((state: any) => state.user);

    if (user && props.role && Array.isArray(props.role) && props.role.indexOf(user.role) != -1) {
        return <Outlet />;
    }
    //   return <Navigate to={"/login"} replace />;

    return <Outlet />;
}


const Fallback = () => {
    return (
        <>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> &nbsp; Loading....
        </>
    )
}

const Routers = () => {
    return (
        <Suspense fallback={<Fallback />}>
            <Routes>
                <Route element={<ChangePassword />} path="/changePassword/:uuid?" />
                <Route element={<Registration />} path="/registration" />
                <Route element={<Login />} path="/login" />
                <Route element={<Logout />} path="/logout" />
                <Route element={<Activate />} path="/activate" />
                <Route element={<ForgottenPassword />} path="/forgot-password" />
                <Route element={<EditProfile />} path="/edit-profile" />
                <Route element={<NewBooking />} path="/new-booking" />
                <Route element={<NewQuote />} path="/new-quote" />
                <Route element={<Bookings />} path="/bookings" />
                <Route element={<Quotes />} path="/quotes" />
                <Route path='/booking' element={<NewBookingForm />} >
                    <Route path=":id" element={<NewBookingForm />} />
                </Route>
                <Route path='/quote' element={<NewQuoteForm />} >
                    <Route path=":id" element={<NewQuoteForm />} />
                </Route>
                <Route element={<Invoices />} path="/invoices" />
                <Route element={<NewBookingForm />} path="/new-booking/form/:serviceType" />
                <Route element={<NewBookingForm />} path="/custom-clearance/form" />
                <Route element={<NewQuoteForm />} path="/new-quote/form/:serviceType" />
                <Route element={<NewQuoteForm />} path="/new-quote/custom-clearance/form" />
                <Route element={<AuthRoute role={['Admin']} />}>
                    <Route path="/" element={<HomePage />} />
                    <Route element={<Users />} path="/admin/users" />
                    <Route path='/user' element={<NewUserForm />} >
                        <Route path=":id" element={<NewUserForm />} />
                    </Route>
                </Route>
            </Routes>
        </Suspense>
    )
}

export default Routers;