import React, { useState, useMemo, useEffect } from 'react';
import { Link as Rlink, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import client from '../services/client';
import { useSelector } from 'react-redux';
import AlertMessage from '../common/AlertMessage';
import { passwordRules } from '../const';
import { debug } from 'console';
type FieldType = {
    password?: string;
    confirm_password?: string;
    currentPassword?: string;
};
let _password: string = ''; // for referencing

const ChangePassword: React.FC = () => {
    const isLogin = useSelector((state: any) => state.isLogin);
    const navigate = useNavigate();
    const colSpan = isLogin ? 8 : 24;
    const [searchParams] = useSearchParams();
    const userId = searchParams.get('userId');
    const code = searchParams.get('code');


    const [Message, setMessage] = useState({ type: '', message: '' });

    const [processing, setProcessing] = useState(false);
    const [ShowForm, setShowForm] = useState(false);

    useEffect(() => {
        if (isLogin) {
            setShowForm(true);
        } else {
            validateUUID();
        }


    }, [])

    const validateUUID = async () => {


        setShowForm(userId !== undefined && code !== undefined);

    }

    const handleSubmit = async (values: any) => {
        setProcessing(true);

        let param: any = {};
        let res: any = null;
        if (isLogin) {
            param = {
                Password: values['password'], ConfirmPassword: values['confirm_password'], CurrentPassword: values['currentPassword']
            };
        }
        else {
            param = {
                Password: values['password'], ConfirmPassword: values['confirm_password'], userId, code
            };
        }


        res = await client.post(isLogin ? 'v1/account/ChangeUserPassword' : 'v1/account/ChangePassword', param);
        if (res && res.success) {
            setShowForm(false);
            setMessage({
                type: 'success', message: 'Password is successfully reset.'
            });


        } else {
            debugger;
            setMessage({ type: 'error', message: res?.response?.data[0]?.description });
        }

        setProcessing(false);
    }

    return (
        <div className={!isLogin ? 'login' : ''}>
            {isLogin ? '' : <div className="logo-mini"></div>}

            <h1>Change Password</h1><br />
            <div className={!isLogin ? 'paper' : 'section mt-10'}>
                <AlertMessage message={Message} />

                {ShowForm ?
                    <> <Form
                        name="basic"
                        //labelCol={{ span: 8 }}
                        //wrapperCol={{ span: 16 }}
                        //style={{ maxWidth: 600 }}

                        onFinish={handleSubmit}

                        autoComplete="off"
                    >


                        {isLogin && <Row gutter={16}><Col span={colSpan} >
                            <Form.Item<FieldType>
                                //label="Full Name"
                                name="currentPassword"
                                rules={passwordRules}
                            >
                                <Input type='password' placeholder="Current Password" />
                            </Form.Item>
                        </Col>
                        </Row>
                        }
                        <Row gutter={16}>
                            <Col span={colSpan} >
                                <Form.Item<FieldType>
                                    //label="Full Name"
                                    name="password"
                                    rules={passwordRules}
                                >
                                    <Input type='password' placeholder="Password" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={colSpan}>
                                <Form.Item<FieldType>
                                    //label="Full Name"
                                    name="confirm_password"
                                    dependencies={['password']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your password.',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The new password that you entered do not match!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input type='password' placeholder="Confirm Password" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Row>
                    </Form>

                    </>


                    :
                    ''
                }<br />
                {!isLogin && <Rlink to="/login">Back</Rlink>}


                <br />
            </div>

        </div>
    )

}

export default ChangePassword;