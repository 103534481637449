import React, { useState, useEffect } from "react";
import client from "./services/client";

import { useSelector, useDispatch } from "react-redux";
import { login, logout } from "./store/actions";
import { Alert, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { debug } from "console";

//import logo from './assets/images/logo.png';

const AppInitializer = (props: any) => {
    const dispatch = useDispatch();
    const isLogin = useSelector((state: any) => state.isLogin);
    const isInitialized = useSelector((state: any) => state.init);
    const [isActivate, setIsActivate] = useState(false);
    const verifyToken = async () => {
        let auth: any = localStorage.getItem("auth");
        if (auth) {
            let user = JSON.parse(auth);
            dispatch(login(user));
        } else {
            if (
                window.location.href.toLowerCase().includes("/activate") ||
                window.location.href.toLowerCase().includes("/changepassword")
            ) {
                setIsActivate(true);
            } else {
                dispatch(logout());
                if (!window.location.href.includes("/login"))
                    window.location.href = "./../login?returnUrl=" + window.location.pathname;
            }
        }
    };

    useEffect(() => {
        if (!isLogin) {
            verifyToken();
        } else {
        }
    }, []);

    return <>{isInitialized || isActivate ? props.children : <SiteLoader />}</>;
};

const SiteLoader = () => {
    return (
        <div className="site-loader">
            <div className="wrapper">
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
                    <Alert
                        message="Cargo Northeast"
                        description="Driving Progress, One Delivery at a Time"
                        type="info"
                    />
                </Spin>
            </div>
        </div>
    );
};

export default AppInitializer;
